import Preact from "preact";

export default function ProductNav(props) {
  const { open = false, onClick } = props;
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <div class="nav-item dd-products">
      <a href="/products">Products</a>
      <span>Products</span>{" "}
      <div class="mobile-products" onClick={handleClick}>
      </div>
      <nav class={`nav-products ${open ? "open" : ""}`}>
        <div class="p-overview">
          <a href="/products" class="btn btn01 sml">
            Products Overview
          </a>
        </div>

        <div class="nav-section">
          <h5>Product Type</h5>

          <a href="/xt-high-performance-thermal-line">
            XT High Performance Thermal Line
          </a>
          <a href="/casement">Casement Windows</a>
          <a href="/curtain-wall-system-details">Curtain Wall</a>
          <a href="/fixed">Fixed Windows</a>
          <a href="/sliders">Horizontal Sliding Windows</a>
          <a href="/hung">Hung Windows</a>
          <a href="/project-in">Project In Windows</a>
          <a href="/project-out">Project Out Windows</a>
          <a href="/patio-doors">Patio Doors</a>
          <a href="/storefront-and-curtain-wall-applied-architectural-muntins">
            Storefront and Curtain Wall Applied Architectural Muntins
          </a>
          <a href="/commercial-storefront-systems">Storefront Systems</a>
          <a href="/commercial-aluminum-storefront-doors">
            Storefront Doors
          </a>
          <a href="/accessories">Window Accessories</a>
        </div>
        <div class="nav-section">
          <h5>Applications</h5>

          <a href="/blast">Blast Resistant Window Systems</a>
          <a href="/finishes">Finish Options</a>
          <a href="/historic">Historic Replication</a>
          <a href="/impact">Hurricane Impact Window Systems</a>
        </div>
      </nav>
    </div>
  );
}
